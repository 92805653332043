@charset "UTF-8";
/*
[TABLE OF CONTENTS]

01. Base
02. Typography
03. Preloader
04. Header
05. Sections
06. Parallax Shapes
07. Skills
08. Service
09. Portfolio
10. Facts
11. Prices
12. Testimonials
13. Blog
14. Clients
15. Contact
16. Helper
17. Dark Mode
18. Buttons
19. Bootstrap Components
20. Theme elements
21. Slick
22. Responsive
*/

/*removed rubik for lexend deca*/

/* lexend-deca-100 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/lexend-deca-v21-latin-100.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lexend-deca-200 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/lexend-deca-v21-latin-200.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lexend-deca-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/lexend-deca-v21-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lexend-deca-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/lexend-deca-v21-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lexend-deca-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/lexend-deca-v21-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lexend-deca-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/lexend-deca-v21-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lexend-deca-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/lexend-deca-v21-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lexend-deca-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/lexend-deca-v21-latin-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* lexend-deca-900 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/lexend-deca-v21-latin-900.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/*=================================================================*/
/*                      BASE                              
/*=================================================================*/
* {
  margin: 0;
  padding: 0;
}

html {
  margin: 0 !important;
  overflow: auto !important;
}

body {
  color: #5E5C7F;
  background-color: #F9F9FF;
  font-family: "Lexend Deca", system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  line-height: 1.7;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  max-width: 100%;
  height: auto;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.relative {
  position: relative;
}

/*=================================================================*/
/*                      TYPOGRAPHY                              
/*=================================================================*/
h1, h2, h3, h4, h5, h6 {
  color: #454360;
  font-family: "Lexend Deca", system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 700;
  margin: 20px 0;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

a {
  color: #FF4C60;
  outline: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
a:hover {
  color: #03C988;
  text-decoration: none;
}
a:focus {
  outline: 0;
}

blockquote {
  padding: 20px 20px;
  margin: 0 0 20px;
  font-size: 16px;
  background: #F7F7F7;
  border-radius: 10px;
}

blockquote p {
  line-height: 1.6;
}

/* === Pre === */
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 0;
}

/*=================================================================*/
/*                      PRELOADER                              
/*=================================================================*/
.infinity {
  width: 120px;
  height: 60px;
  position: relative;
}
.infinity div,
.infinity span {
  position: absolute;
}
.infinity div {
  top: 0;
  left: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: rotate 6.9s linear infinite;
          animation: rotate 6.9s linear infinite;
}
.infinity div span {
  left: -8px;
  top: 50%;
  margin: -8px 0 0 0;
  width: 16px;
  height: 16px;
  display: block;
  background: #FF4C60;
  -webkit-box-shadow: 2px 2px 8px rgba(255, 76, 96, 0.09);
          box-shadow: 2px 2px 8px rgba(255, 76, 96, 0.09);
  border-radius: 50%;
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-animation: move 6.9s linear infinite;
          animation: move 6.9s linear infinite;
}
.infinity div span:before, .infinity div span:after {
  content: "";
  position: absolute;
  display: block;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background: inherit;
  top: 50%;
  left: 50%;
  margin: -7px 0 0 -7px;
  -webkit-box-shadow: inherit;
          box-shadow: inherit;
}
.infinity div span:before {
  -webkit-animation: drop1 0.8s linear infinite;
          animation: drop1 0.8s linear infinite;
}
.infinity div span:after {
  -webkit-animation: drop2 0.8s linear infinite 0.4s;
          animation: drop2 0.8s linear infinite 0.4s;
}
.infinity div:nth-child(2) {
  -webkit-animation-delay: -2.3s;
          animation-delay: -2.3s;
}
.infinity div:nth-child(2) span {
  -webkit-animation-delay: -2.3s;
          animation-delay: -2.3s;
}
.infinity div:nth-child(3) {
  -webkit-animation-delay: -4.6s;
          animation-delay: -4.6s;
}
.infinity div:nth-child(3) span {
  -webkit-animation-delay: -4.6s;
          animation-delay: -4.6s;
}

.infinityChrome {
  width: 128px;
  height: 60px;
}
.infinityChrome div {
  position: absolute;
  width: 16px;
  height: 16px;
  background: #FF4C60;
  -webkit-box-shadow: 2px 2px 8px rgba(255, 76, 96, 0.09);
          box-shadow: 2px 2px 8px rgba(255, 76, 96, 0.09);
  border-radius: 50%;
  -webkit-animation: moveSvg 6.9s linear infinite;
          animation: moveSvg 6.9s linear infinite;
  -webkit-filter: url(#goo);
  filter: url(#goo);
  -webkit-transform: scaleX(-1);
      -ms-transform: scaleX(-1);
          transform: scaleX(-1);
  offset-path: path("M64.3636364,29.4064278 C77.8909091,43.5203348 84.4363636,56 98.5454545,56 C112.654545,56 124,44.4117395 124,30.0006975 C124,15.5896556 112.654545,3.85282763 98.5454545,4.00139508 C84.4363636,4.14996252 79.2,14.6982509 66.4,29.4064278 C53.4545455,42.4803627 43.5636364,56 29.4545455,56 C15.3454545,56 4,44.4117395 4,30.0006975 C4,15.5896556 15.3454545,4.00139508 29.4545455,4.00139508 C43.5636364,4.00139508 53.1636364,17.8181672 64.3636364,29.4064278 Z");
}
.infinityChrome div:before, .infinityChrome div:after {
  content: "";
  position: absolute;
  display: block;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background: inherit;
  top: 50%;
  left: 50%;
  margin: -7px 0 0 -7px;
  -webkit-box-shadow: inherit;
          box-shadow: inherit;
}
.infinityChrome div:before {
  -webkit-animation: drop1 0.8s linear infinite;
          animation: drop1 0.8s linear infinite;
}
.infinityChrome div:after {
  -webkit-animation: drop2 0.8s linear infinite 0.4s;
          animation: drop2 0.8s linear infinite 0.4s;
}
.infinityChrome div:nth-child(2) {
  -webkit-animation-delay: -2.3s;
          animation-delay: -2.3s;
}
.infinityChrome div:nth-child(3) {
  -webkit-animation-delay: -4.6s;
          animation-delay: -4.6s;
}

@-webkit-keyframes moveSvg {
  0% {
    offset-distance: 0%;
  }
  25% {
    background: #6C6CE5;
  }
  75% {
    background: #FFD15C;
  }
  100% {
    offset-distance: 100%;
  }
}

@keyframes moveSvg {
  0% {
    offset-distance: 0%;
  }
  25% {
    background: #6C6CE5;
  }
  75% {
    background: #FFD15C;
  }
  100% {
    offset-distance: 100%;
  }
}
@-webkit-keyframes rotate {
  50% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    margin-left: 0;
  }
  50.0001%, 100% {
    margin-left: -60px;
  }
}
@keyframes rotate {
  50% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    margin-left: 0;
  }
  50.0001%, 100% {
    margin-left: -60px;
  }
}
@-webkit-keyframes move {
  0%, 50% {
    left: -8px;
  }
  25% {
    background: #FFD15C;
  }
  75% {
    background: #6C6CE5;
  }
  50.0001%, 100% {
    left: auto;
    right: -8px;
  }
}
@keyframes move {
  0%, 50% {
    left: -8px;
  }
  25% {
    background: #FFD15C;
  }
  75% {
    background: #6C6CE5;
  }
  50.0001%, 100% {
    left: auto;
    right: -8px;
  }
}
@-webkit-keyframes drop1 {
  100% {
    -webkit-transform: translate(32px, 8px) scale(0);
            transform: translate(32px, 8px) scale(0);
  }
}
@keyframes drop1 {
  100% {
    -webkit-transform: translate(32px, 8px) scale(0);
            transform: translate(32px, 8px) scale(0);
  }
}
@-webkit-keyframes drop2 {
  0% {
    -webkit-transform: translate(0, 0) scale(0.9);
            transform: translate(0, 0) scale(0.9);
  }
  100% {
    -webkit-transform: translate(32px, -8px) scale(0);
            transform: translate(32px, -8px) scale(0);
  }
}
@keyframes drop2 {
  0% {
    -webkit-transform: translate(0, 0) scale(0.9);
            transform: translate(0, 0) scale(0.9);
  }
  100% {
    -webkit-transform: translate(32px, -8px) scale(0);
            transform: translate(32px, -8px) scale(0);
  }
}
#preloader {
  background: #353353;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 1031;
}
#preloader .outer {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
#preloader.light {
  background: #F9F9FF;
}

.goo-outer {
  display: none;
}

/*=================================================================*/
/*                      HEADER                              
/*=================================================================*/
header.desktop-header-1 {
  color: #FFF;
  border-right: solid 1px rgba(255, 255, 255, 0.1);
  background: #353353;
  padding: 50px 40px 40px;
  position: fixed;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  min-height: 100vh;
  top: 0;
  width: 290px;
  z-index: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  /* === Vertical Menu === */
  /* === Submenu === */
}
header.desktop-header-1.open {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
header.desktop-header-1 .vertical-menu {
  list-style: none;
  padding: 0;
  margin-bottom: 130px;
  margin-top: 130px;
}
header.desktop-header-1 .vertical-menu li.openmenu > a {
  color: #000;
}
header.desktop-header-1 .vertical-menu li.openmenu .switch {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
header.desktop-header-1 .vertical-menu li {
  padding: 8px 0;
  position: relative;
  list-style: none;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
header.desktop-header-1 .vertical-menu li a:hover {
  color: #FFD15C;
}
header.desktop-header-1 .vertical-menu li i {
  color: #FFD15C;
  margin-right: 20px;
}
header.desktop-header-1 .vertical-menu li .switch {
  font-size: 30px;
  display: inline-block;
  padding: 0 14px;
  line-height: 1;
  cursor: pointer;
  color: #000;
  position: absolute;
  top: 24px;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-backface-visibility: hidden;
}
header.desktop-header-1 .vertical-menu li a {
  color: #FFF;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  text-decoration: none;
  position: relative;
}
header.desktop-header-1 .vertical-menu li a.nav-link {
  display: initial;
  padding: 0;
}
header.desktop-header-1 .vertical-menu li .nav-link.active {
  color: #FFD15C;
}
header.desktop-header-1 .submenu {
  display: none;
  padding: 0;
}
header.desktop-header-1 .submenu li a {
  color: #000;
  font-size: 18px;
  font-weight: 700;
}
header.desktop-header-1 .submenu li a:after {
  height: 3px;
}
header.desktop-header-1 .submenu li a:hover:after {
  width: 100%;
  height: 3px;
}
header.desktop-header-1 .submenu li a:hover {
  color: #000;
}
header.desktop-header-1 .footer {
  margin-top: auto;
}
header.desktop-header-1 .copyright {
  color: #9C9AB3;
  font-size: 14px;
}
header.desktop-header-1.light {
  background: #F9F9FF;
  border-right: solid 1px rgba(0, 0, 0, 0.05);
}
header.desktop-header-1.light .vertical-menu li .nav-link.active {
  color: #FF4C60;
}
header.desktop-header-1.light .vertical-menu li a {
  color: #353353;
}
header.desktop-header-1.light .vertical-menu li a:hover {
  color: #FF4C60;
}
header.desktop-header-1.light .vertical-menu li i {
  color: #FF4C60;
}

header.desktop-header-2 {
  color: #FFF;
  border-right: solid 1px rgba(255, 255, 255, 0.1);
  background: #353353;
  padding: 40px;
  position: fixed;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  min-height: 100vh;
  top: 0;
  width: 110px;
  z-index: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  /* === Vertical Menu === */
}
header.desktop-header-2.open {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
header.desktop-header-2 .vertical-menu {
  list-style: none;
  padding: 0;
  margin-top: 100px;
  margin-bottom: 0;
}
header.desktop-header-2 .vertical-menu li.openmenu > a {
  color: #000;
}
header.desktop-header-2 .vertical-menu li.openmenu .switch {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
header.desktop-header-2 .vertical-menu li {
  padding: 8px 0;
  position: relative;
  list-style: none;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
header.desktop-header-2 .vertical-menu li a:hover {
  color: #FFD15C;
}
header.desktop-header-2 .vertical-menu li a:hover i {
  color: #FFD15C;
  opacity: 1;
}
header.desktop-header-2 .vertical-menu li i {
  color: #FFF;
  margin-right: 20px;
  opacity: 0.4;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
header.desktop-header-2 .vertical-menu li a {
  color: #FFF;
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-weight: 700;
  width: 100%;
  text-decoration: none;
  position: relative;
}
header.desktop-header-2 .vertical-menu li a.nav-link {
  display: initial;
  padding: 0;
}
header.desktop-header-2 .vertical-menu li .nav-link.active {
  color: #FFD15C;
}
header.desktop-header-2 .vertical-menu li .nav-link.active i {
  color: #FFD15C;
  opacity: 1;
}
header.desktop-header-2 .footer {
  margin-top: auto;
  position: relative;
}
header.desktop-header-2 .copyright {
  color: #9C9AB3;
  font-size: 14px;
  -webkit-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
          transform: rotate(-180deg);
  -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
  white-space: nowrap;
  display: inline-block;
  overflow: visible;
}
header.desktop-header-2.light {
  background: #F9F9FF;
  border-right: solid 1px rgba(0, 0, 0, 0.05);
}
header.desktop-header-2.light .vertical-menu li i {
  color: #353353;
  opacity: 1;
}

header.mobile-header-1 {
  background: #353353;
  display: none;
  padding: 10px 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
header.mobile-header-1.open {
  -webkit-transform: translateX(290px);
  -ms-transform: translateX(290px);
  transform: translateX(290px);
}
header.mobile-header-1 .menu-icon button {
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;
  height: 33px;
  width: 33px;
}
header.mobile-header-1 .menu-icon span {
  display: block;
  background: #FFF;
  border-radius: 5px;
  height: 4px;
  position: relative;
  width: 30px;
}
header.mobile-header-1 .menu-icon span:before {
  content: "";
  display: block;
  background: #FFF;
  border-radius: 5px;
  height: 4px;
  top: -10px;
  position: absolute;
  width: 30px;
}
header.mobile-header-1 .menu-icon span:after {
  content: "";
  display: block;
  background: #FFF;
  border-radius: 5px;
  height: 4px;
  top: 10px;
  position: absolute;
  width: 30px;
}
header.mobile-header-1 .site-logo {
  display: inline-block;
}
header.mobile-header-1 .site-logo img {
  max-height: 30px;
}
header.mobile-header-1.light {
  background: #F9F9FF;
}
header.mobile-header-1.light .menu-icon span {
  background: #353353;
}
header.mobile-header-1.light .menu-icon span:before, header.mobile-header-1.light .menu-icon span:after {
  background: #353353;
}

header.mobile-header-2 {
  background: #353353;
  display: none;
  padding: 10px 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
header.mobile-header-2.open {
  -webkit-transform: translateX(110px);
  -ms-transform: translateX(110px);
  transform: translateX(110px);
}
header.mobile-header-2 .menu-icon button {
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;
  height: 33px;
  width: 33px;
}
header.mobile-header-2 .menu-icon span {
  display: block;
  background: #FFF;
  border-radius: 5px;
  height: 4px;
  position: relative;
  width: 30px;
}
header.mobile-header-2 .menu-icon span:before {
  content: "";
  display: block;
  background: #FFF;
  border-radius: 5px;
  height: 4px;
  top: -10px;
  position: absolute;
  width: 30px;
}
header.mobile-header-2 .menu-icon span:after {
  content: "";
  display: block;
  background: #FFF;
  border-radius: 5px;
  height: 4px;
  top: 10px;
  position: absolute;
  width: 30px;
}
header.mobile-header-2 .site-logo {
  display: inline-block;
}
header.mobile-header-2 .site-logo img {
  max-height: 30px;
}

.desktop-header-3 {
  background: #353353;
  padding: 20px 0;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}
.desktop-header-3 .navbar {
  padding: 0;
}
.desktop-header-3 .navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
}
.desktop-header-3 .navbar-dark .navbar-nav .nav-link {
  color: #FFF;
}
.desktop-header-3 .nav-link {
  font-size: 16px;
  font-weight: 700;
  padding: 0;
}
.desktop-header-3 .nav-link.active {
  color: #FFD15C !important;
}
.desktop-header-3 .nav-link:hover {
  color: #FFD15C !important;
}
.desktop-header-3 .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 0.25rem;
}
.desktop-header-3 button.navbar-toggler:focus {
  outline: 0;
  outline: 0;
}
.desktop-header-3 .navbar-nav li:not(:last-child) {
  padding-right: 3rem;
}
@media (min-width: 992px) {
  .desktop-header-3 .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
}
.desktop-header-3.light {
  background: #F9F9FF;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}
.desktop-header-3.light .navbar-dark .navbar-nav .nav-link {
  color: #353353;
}

/*=================================================================*/
/*                      SECTIONS                             
/*=================================================================*/
main.content {
  margin-left: 290px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
main.content.open {
  opacity: 0.8;
  -webkit-transform: translateX(290px);
  -ms-transform: translateX(290px);
  transform: translateX(290px);
}

main.content-2 {
  margin-left: 110px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
main.content-2.open {
  opacity: 0.8;
  -webkit-transform: translateX(110px);
  -ms-transform: translateX(110px);
  transform: translateX(110px);
}

section {
  padding-top: 110px;
  position: relative;
}

section.home {
  background: #353353;
  padding: 0;
  height: 100vh;
  min-height: 100vh;
}
section.home .intro {
  margin: auto;
  max-width: 540px;
  text-align: center;
  position: relative;
  z-index: 1;
}
section.home .intro h1 {
  color: #FFF;
  font-size: 36px;
}
section.home .intro span {
  color: #FFF;
  font-size: 16px;
}
section.home .social-icons li a {
  color: #FFF;
}
section.home.light {
  background: #F9F9FF;
}
section.home.light .intro h1 {
  color: #353353;
}
section.home.light .intro span {
  color: #454360;
}
section.home.light .social-icons li a {
  color: #353353;
}
section.home.light .social-icons li a:hover {
  color: #FFD15C;
}

.section-title {
  font-size: 36px;
  margin: 0;
  margin-left: 14px;
  position: relative;
}
.section-title:before {
  content: "";
  /*background-image: url(../images/dots-bg.svg);*/
  display: block;
  height: 37px;
  left: -14px;
  top: -14px;
  position: absolute;
  width: 37px;
}

/*=================================================================*/
/*                      PARALLAX SHAPES                              
/*=================================================================*/
.parallax {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.parallax .layer {
  position: absolute;
}
.parallax .p1 {
  left: 10%;
  top: 10%;
}
.parallax .p2 {
  left: 25%;
  top: 30%;
}
.parallax .p3 {
  left: 15%;
  bottom: 30%;
}
.parallax .p4 {
  left: 10%;
  bottom: 10%;
}
.parallax .p5 {
  left: 45%;
  top: 10%;
}
.parallax .p6 {
  left: 40%;
  bottom: 10%;
}
.parallax .p7 {
  top: 20%;
  right: 30%;
}
.parallax .p8 {
  right: 30%;
  bottom: 20%;
}
.parallax .p9 {
  right: 10%;
  top: 10%;
}
.parallax .p10 {
  top: 45%;
  right: 20%;
}
.parallax .p11 {
  bottom: 10%;
  right: 10%;
}

/*=================================================================*/
/*                      SKILLS                              
/*=================================================================*/
.skill-item .skill-info h4 {
  font-size: 16px;
  font-weight: 500;
}
.skill-item .skill-info span {
  font-size: 14px;
}

/*=================================================================*/
/*                      SERVICE                              
/*=================================================================*/
.service-box {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.service-box h3 {
  font-size: 20px;
}
.service-box img {
  margin-bottom: 22px;
}
.service-box:hover {
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
}

/*=================================================================*/
/*                      PORTFOLIO                              
/*=================================================================*/
.portfolio-wrapper [class*=col-] {
  margin-top: 15px;
  margin-bottom: 15px;
}

.pf-filter-wrapper {
  display: none;
}

.portfolio-item {
  position: relative;
  overflow: hidden;
}
.portfolio-item .thumb {
  overflow: hidden;
}
.portfolio-item .details {
  color: #FFF;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.portfolio-item .details h4 {
  color: #FFF;
  font-size: 20px;
  margin: 0 0 10px;
  padding: 0 20px;
  opacity: 0;
  -webkit-transform: translateY(30px);
      -ms-transform: translateY(30px);
          transform: translateY(30px);
  -webkit-transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
  -o-transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
  position: absolute;
  top: 60px;
}
.portfolio-item .details span.term {
  color: #FFF;
  background: #FF4C60;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  font-size: 14px;
  opacity: 0;
  display: inline-block;
  padding: 3px 10px;
  position: absolute;
  top: 0;
  left: 20px;
  -webkit-transform: translateY(-40px);
      -ms-transform: translateY(-40px);
          transform: translateY(-40px);
  -webkit-transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
  -o-transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
}
.portfolio-item .details .more-button {
  color: #FFF;
  font-size: 20px;
  display: block;
  background: #FFD15C;
  border-radius: 100%;
  height: 40px;
  line-height: 42px;
  text-align: center;
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 40px;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.portfolio-item .mask {
  background: #526D82;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.portfolio-item:hover .mask {
  opacity: 0.9;
}
.portfolio-item:hover .details h4, .portfolio-item:hover .details span {
  opacity: 1;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
}
.portfolio-item:hover .details .more-button {
  opacity: 1;
}

.portfolio-filter {
  margin-bottom: 25px;
}
.portfolio-filter li {
  color: #5E5C7F;
  cursor: pointer;
  font-family: "Lexend Deca", system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 700;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.portfolio-filter li:hover {
  color: #FF4C60;
}
.portfolio-filter li.current {
  color: #FF4C60;
}
.portfolio-filter li:not(:last-child) {
  margin-right: 1.8rem;
}

select.portfolio-filter-mobile {
  font-size: 16px;
  border-radius: 10px;
  outline: 0;
  margin-bottom: 30px;
  position: relative;
  width: 100%;
}

.pf-filter-wrapper {
  position: relative;
}
.pf-filter-wrapper:after {
  content: "";
  font-family: "FontAwesome";
  position: absolute;
  top: 0;
  right: 20px;
  color: #000;
}

/*=================================================================*/
/*                      FACTS                              
/*=================================================================*/
.fact-item .details {
  margin-left: 60px;
}
.fact-item .icon {
  font-size: 36px;
  color: #DEDEEA;
  float: left;
}
.fact-item .number {
  font-size: 30px;
}
.fact-item .number em {
  font-style: normal;
}
.fact-item p {
  font-size: 16px;
}

/*=================================================================*/
/*                      PRICES                              
/*=================================================================*/
.price-item {
  padding: 30px 40px;
}
.price-item h2.plan {
  font-size: 24px;
}
.price-item .price {
  font-size: 36px;
  font-weight: 700;
  margin: 0;
}
.price-item .price em {
  font-style: normal;
  font-size: 14px;
  vertical-align: super;
  margin-right: 5px;
}
.price-item .price span {
  font-size: 16px;
  font-weight: 400;
  margin-left: 10px;
}
.price-item ul {
  margin-bottom: 0;
}
.price-item ul li {
  line-height: 2.1;
}
.price-item .btn {
  margin-top: 20px;
}
.price-item.best {
  padding: 55px 40px;
  z-index: 1;
  position: relative;
}
.price-item .badge {
  color: #FFF;
  background: #6C6CE5;
  -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
  position: absolute;
  left: 0;
  top: 46px;
  padding: 7px 12px;
  font-size: 14px;
  font-weight: 400;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/*=================================================================*/
/*                      TESTIMONIALS                              
/*=================================================================*/
.testimonials-wrapper {
  max-width: 700px;
  margin: auto;
}

.testimonial-item {
  padding: 0 20px 20px;
}
.testimonial-item .thumb {
  border-radius: 50%;
  overflow: hidden;
  height: 90px;
  width: 90px;
}
.testimonial-item .subtitle {
  color: #8B88B1;
  font-size: 14px;
}
.testimonial-item p {
  font-size: 16px;
}

/*=================================================================*/
/*                      BLOG                              
/*=================================================================*/
.blog-item {
  overflow: hidden;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.blog-item:hover {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(-10px);
}
.blog-item .thumb {
  position: relative;
  overflow: hidden;
}
.blog-item .thumb img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.blog-item .thumb:hover img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.blog-item .category {
  background: #FF4C60;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  color: #FFF;
  font-size: 14px;
  padding: 2px 8px;
  position: absolute;
  left: 20px;
  top: 0;
  z-index: 1;
}
.blog-item .details {
  padding: 20px;
}
.blog-item .details h4.title {
  font-size: 20px;
}
.blog-item .details h4.title a {
  color: #353353;
}
.blog-item .details h4.title a:hover {
  color: #FF4C60;
}
.blog-item .meta {
  font-size: 14px;
  color: #8B88B1;
}
.blog-item .meta li:not(:last-child) {
  margin-right: 0.6rem;
}
.blog-item .meta li:after {
  content: "";
  background: #8B88B1;
  border-radius: 50%;
  display: inline-block;
  height: 3px;
  margin-left: 0.6rem;
  vertical-align: middle;
  width: 3px;
}
.blog-item .meta li:last-child::after {
  display: none;
}

.blog-page-section {
  background-color: #f9f9ff;
  padding-bottom: 70px;
}
.blog-page-section .blog-img-text {
  padding: 30px;
  -webkit-box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.1);
          box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.1);
}
.blog-page-section .blog-text-title h4 {
  font-size: 30px;
}
.blog-page-section .blog-text-title h4 a {
  color: #454360;
}
.blog-page-section .blog-text-title h4 a:hover {
  color: #FF4C60;
}
.blog-page-section .blog-pagination {
  margin: 60px 0px 40px;
}
.blog-page-section .blog-pagination ul li {
  height: 50px;
  width: 50px;
  line-height: 50px;
  margin: 0px 10px;
  border-radius: 100%;
  display: inline-block;
  -webkit-box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.1);
          box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.1);
}
.blog-page-section .blog-pagination ul li a {
  width: 100%;
  display: block;
  font-weight: 500;
  border-radius: 100%;
}
.blog-page-section .blog-pagination ul li a:hover {
  background-color: #FF4C60;
}
.blog-page-section .blog-standard {
  margin-bottom: 30px;
}
.blog-page-section .blog-standard .meta {
  font-size: 14px;
  color: #8b88b1;
}
.blog-page-section .blog-standard .meta li:after {
  content: "";
  background: #8b88b1;
  border-radius: 50%;
  display: inline-block;
  height: 3px;
  margin-left: 0.6rem;
  vertical-align: middle;
  width: 3px;
}
.blog-page-section .blog-standard .meta li:last-child:after {
  display: none;
}
.blog-page-section .blog-standard .meta li:not(:last-child) {
  margin-right: 0.6rem;
}
.blog-page-section .blog-standard .meta li a {
  color: #353353;
}
.blog-page-section .blog-standard .meta li a:hover {
  color: #ff4c60;
}
.blog-page-section .br-more a {
  border-radius: 60px;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;
  line-height: 1;
  padding: 12px 32px;
  color: #fff;
  background: #ff4c60;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
          box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.blog-page-section .br-more a:hover {
  color: #fff;
  -webkit-animation-name: button-push;
  animation-name: button-push;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
.blog-page-section .widget {
  margin-bottom: 30px;
  padding: 20px;
}
.blog-page-section .screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}
.blog-page-section .widget .searchform input[type=text] {
  border-radius: 30px;
  border: 0;
  background: #f3f3f3;
  padding: 6px 20px;
  max-width: 190px;
}
.blog-page-section .widget .searchform input[type=submit] {
  color: #fff;
  background: #ff4c60;
  border-radius: 25px;
  padding: 5px 20px;
  border: 0;
  cursor: pointer;
}
.blog-page-section .shadow-dark, .blog-page-section .form-control, .blog-page-section .desktop-header-3 .dropdown-menu, .desktop-header-3 .blog-page-section .dropdown-menu {
  -webkit-box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.1);
          box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.1);
}
.blog-page-section .widget-header {
  margin-bottom: 15px;
  margin-top: 0;
}
.blog-page-section .widget ul {
  padding-left: 1.1rem;
}
.blog-page-section .blog-single {
  background: #fff;
  padding: 30px;
  border-radius: 20px;
}
.blog-page-section .comment-respond {
  margin-top: 2.5rem;
  border-top: solid 1px #eee;
  clear: both;
}
.blog-page-section .comment-respond .comment-notes {
  font-style: italic;
}
.blog-page-section .comment-respond label {
  display: block;
  margin-bottom: 0.5rem;
}
.blog-page-section .comment-respond textarea,
.blog-page-section .comment-respond input[type=text],
.blog-page-section .comment-respond input[type=email],
.blog-page-section .comment-respond input[type=url] {
  border-radius: 30px;
  border: 0;
  background: #f3f3f3;
  padding: 6px 20px;
  outline: 0;
  max-width: 100%;
}
.blog-page-section .comment-respond textarea {
  height: 10rem;
}
.blog-page-section .comment-form-cookies-consent {
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.blog-page-section .comment-respond input[type=checkbox] + label {
  font-size: 14px;
  margin-left: 10px;
}
.blog-page-section .comment-respond input[type=submit] {
  border-radius: 60px;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;
  line-height: 1;
  padding: 12px 32px;
  color: #fff;
  background: #ff4c60;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  border: none;
  cursor: pointer;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
          box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.blog-page-section .comment-respond input[type=submit]:focus {
  outline: none;
}
.blog-page-section .comment-respond input[type=submit]:hover {
  color: #fff;
  -webkit-animation-name: button-push;
  animation-name: button-push;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
.blog-page-section .comment-area-section {
  padding-bottom: 10px;
}
.blog-page-section .comment-area-section h3 {
  margin: 0;
  padding-bottom: 30px;
}
.blog-page-section .comment-area-section .comment-inner-box {
  margin-bottom: 40px;
}
.blog-page-section .comment-area-section .comment-inner-box.reply-comment {
  margin-left: 50px;
}
.blog-page-section .comment-area-section .comment-inner-box .comment-author-img {
  width: 90px;
  height: 90px;
  overflow: hidden;
  border-radius: 100%;
  margin-right: 20px;
}
.blog-page-section .comment-area-section .comment-inner-box .comment-author-text {
  overflow: hidden;
}
.blog-page-section .comment-area-section .comment-inner-box .comment-author-text h4 {
  margin: 0;
  padding-bottom: 5px;
}
.blog-page-section .comment-area-section .comment-inner-box .comment-author-text span {
  font-size: 14px;
  color: #6d6d6d;
}
.blog-page-section .comment-area-section .comment-inner-box .comment-author-text p {
  margin-bottom: 0;
  padding-bottom: 10px;
}
.blog-page-section .comment-area-section .comment-inner-box .comment-author-text .reply-btn a {
  color: #fff;
  border-radius: 20px;
  padding: 0px 10px;
  display: inline-block;
  background-color: #ff4c60;
}

/*=================================================================*/
/*                      CLIENTS                              
/*=================================================================*/
.client-item {
  min-height: 50px;
  position: relative;
  text-align: center;
  margin-top: 60px;
}
.client-item .inner {
  text-align: center;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}
.client-item img {
  display: inline-block;
}

/*=================================================================*/
/*                      CONTACT                              
/*=================================================================*/
.contact-info {
  /*background-image: url("../images/map.svg");*/
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 200px;
}
.contact-info h3 {
  font-size: 23px;
  margin: 0 0 10px;
}

/*=================================================================*/
/*                      HELPER                              
/*=================================================================*/
.help-block.with-errors {
  color: red;
  font-size: 12px;
  padding-left: 30px;
}
.help-block.with-errors ul {
  margin-bottom: 0;
}

.spacer {
  clear: both;
}

.text-link {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}

#infscr-loading {
  position: absolute;
  left: 50%;
  margin-left: -15px;
  bottom: 10px;
}

#infscr-loading img {
  display: none;
}

.fa-spinner {
  margin-right: 10px;
  display: none;
}

.scroll-down {
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
  z-index: 1;
}
.scroll-down.light span {
  color: #454360;
}
.scroll-down.light .mouse {
  border-color: #353353;
}
.scroll-down.light .mouse .wheel {
  background: #353353;
}

@-webkit-keyframes ani-mouse {
  0% {
    top: 29%;
  }
  15% {
    top: 50%;
  }
  50% {
    top: 50%;
  }
  100% {
    top: 29%;
  }
}
@keyframes ani-mouse {
  0% {
    top: 29%;
  }
  15% {
    top: 50%;
  }
  50% {
    top: 50%;
  }
  100% {
    top: 29%;
  }
}
.mouse-wrapper {
  color: #FFF;
  font-size: 14px;
  display: block;
  max-width: 100px;
  margin: auto;
  text-align: center;
}
.mouse-wrapper:hover {
  color: #FFF;
}

.mouse {
  border: solid 2px #FFF;
  border-radius: 16px;
  display: block;
  margin: auto;
  margin-top: 10px;
  height: 26px;
  position: relative;
  width: 20px;
}
.mouse .wheel {
  background: #FFF;
  border-radius: 100%;
  display: block;
  position: absolute;
  top: 8px;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  height: 4px;
  width: 4px;
  -webkit-animation: ani-mouse 2s linear infinite;
  animation: ani-mouse 2s linear infinite;
}

.circle {
  border-radius: 100%;
}

footer.footer {
  background: #353353;
  padding: 40px 0;
  text-align: center;
}
footer.footer .copyright {
  color: #9C9AB3;
  font-size: 14px;
}
footer.footer.light {
  background: #F9F9FF;
  border-top: solid 1px rgba(0, 0, 0, 0.05);
}

/*=================================================================*/
/*                      DARK MODE                              
/*=================================================================*/
body.dark {
  color: #FFF;
  background-color: #353353;
}
body.dark .section-title {
  color: #FFF;
}
body.dark .section-title:before {
  background: url("../images/dots-bg-light.svg");
}
body.dark .bg-dark {
  background: #302f4e !important;
}
body.dark h1, body.dark h2, body.dark h3, body.dark h4, body.dark h5, body.dark h6 {
  color: #FFF;
}
body.dark .triangle-top:before {
  border-bottom-color: #302f4e;
}
body.dark .timeline.exp .timeline-container::after, body.dark .timeline.edu .timeline-container::after {
  background: #302f4e;
}
body.dark .portfolio-filter li {
  color: #FFF;
}
body.dark a:hover {
  color: #FFF;
}
body.dark .portfolio-filter li.current {
  color: #FF4C60;
}
body.dark .blog-item .details h4.title a {
  color: #FFF;
}
body.dark .blog-item .details h4.title a:hover {
  color: #FF4C60;
}
body.dark .contact-info {
  background-image: url("../images/map-light.png");
}
body.dark .white-popup {
  background: #302f4e;
}
body.dark footer.footer {
  border-top: solid 1px rgba(255, 255, 255, 0.1);
}
body.dark .content-blog,
body.dark .blog-page-section {
  background: #353353;
}
body.dark .bg-white {
  background-color: #302f4e !important;
}
body.dark .blog-standard .meta li a, body.dark .blog-standard .title a, body.dark .comment-author .fn {
  color: #fff;
}
body.dark .blog-page-section .blog-pagination ul li {
  background-color: #302f4e !important;
}
body.dark .blog-page-section .blog-single {
  background-color: #302f4e !important;
}

/*=================================================================*/
/*                     BUTTONS
/*=================================================================*/
.btn {
  border-radius: 30px;
  font-family: 'Lexend Deca',system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 800;
  overflow: hidden;
  line-height: 1;
  padding: 12px 32px;
  position: relative;
}
.btn:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn:focus {
  outline: 0;
}

@-webkit-keyframes button-push {
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes hvr-push {
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes hvr-push {
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.btn-default {
  color: #FFF;
  background: #FF4C60;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0);
          box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.btn-default:hover {
  color: #FFF;
  -webkit-animation-name: button-push;
  animation-name: button-push;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.btn-full {
  text-align: center;
  width: 100%;
}

.btn-group-lg > .btn, .btn-lg {
  padding: 28px 30px;
  font-size: 18px;
  font-weight: 100;
  line-height: 0;
}

.btn-sm {
  padding: 17px 16px;
  font-size: 12px;
  line-height: 0;
}

.btn-xs {
  padding: 12px 10px;
  font-size: 12px;
  line-height: 0;
}

.btn.disabled, .btn:disabled {
  opacity: 1;
  cursor: not-allowed;
}

/*=================================================================*/
/*                     BOOTSTRAP ELEMENTS
/*=================================================================*/
.breadcrumb {
  font-size: 14px;
  padding: 0;
  margin-bottom: 0;
  list-style: none;
  background-color: transparent;
  border-radius: 0;
}

.breadcrumb > .active {
  color: #9c9c9c;
}

/* === Progress Bar === */
.progress {
  height: 7px;
  margin-bottom: 0;
  overflow: hidden;
  background-color: #F1F1F1;
  border-radius: 15px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.progress-bar {
  border-radius: 15px;
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 7px;
  color: #fff;
  text-align: center;
  background-color: #353353;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

/* === Form Control === */
.form-control {
  display: block;
  border-radius: 30px;
  width: 100%;
  height: 60px;
  padding: 10px 30px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  color: #454360;
  background-color: #FFF;
  background-image: none;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-control:focus {
  outline: 0;
}

.form-control::-webkit-input-placeholder {
  /* Edge */
  color: #AEACCA;
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #AEACCA;
}

.form-control::-moz-placeholder {
  color: #AEACCA;
}

.form-control::-ms-input-placeholder {
  color: #AEACCA;
}

.form-control::placeholder {
  color: #AEACCA;
}

.form-group {
  margin-bottom: 30px;
}

.input-group-addon {
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: transparent;
  border: 0;
  border-radius: 0;
}

/* === Alert === */
.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 20px;
}

.alert-dismissable .close, .alert-dismissible .close {
  position: relative;
  top: 0;
  right: 0;
  color: inherit;
}

/* === Pagination === */
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 0;
  border-radius: 4px;
}

.pagination > li:first-child > a, .pagination > li:first-child > span {
  margin-left: 0;
  border-radius: 50%;
}

.pagination > li:last-child > a, .pagination > li:last-child > span {
  border-radius: 50%;
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #454360;
  border-color: transparent;
}

.pagination > li > a, .pagination > li > span {
  position: relative;
  float: left;
  font-weight: 400;
  height: 46px;
  line-height: 32px !important;
  margin-left: 11px;
  line-height: 1.42857143;
  color: #1a1a1a;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #EEE;
  border-radius: 50%;
  width: 46px;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.pagination > li > a:focus, .pagination > li > a:hover, .pagination > li > span:focus, .pagination > li > span:hover {
  z-index: 2;
  color: #FFF;
  background-color: #454360;
  border-color: transparent;
}

/*=================================================================*/
/*                      THEME ELEMENTS
/*=================================================================*/
/* === Timeline === */
.timeline {
  position: relative;
}

.timeline .timeline-container {
  padding-left: 50px;
  margin-bottom: 50px;
  position: relative;
  background-color: inherit;
  width: 100%;
}
.timeline .timeline-container:last-of-type {
  margin-bottom: 0;
}

.timeline.edu .timeline-container::after {
  content: "";
  font-family: "simple-line-icons";
  font-size: 24px;
  color: #FF4C60;
  position: absolute;
  left: -7px;
  top: 0;
  z-index: 1;
}

.timeline.exp .timeline-container::after {
  content: "";

  font-family: "simple-line-icons";
  font-size: 24px;
  color: #FF4C60;
  position: absolute;
  left: -7px;
  top: 0;
  z-index: 1;
}

.timeline .content {
  position: relative;
}

.timeline .content .time {
  color: #8B88B1;
  font-size: 14px;
}

.timeline .content h3 {
  font-size: 20px;
  margin: 10px 0;
}

.timeline .content p {
  margin: 0;
}

.timeline span.line {
  position: absolute;
  width: 1px;
  background-color: #FF4C60;
  top: 30px;
  bottom: 30px;
  left: 34px;
}

/* === Social Icons === */
.social-icons li:not(:last-child) {
  margin-right: 1.5rem;
}
.social-icons li a {
  font-size: 21px;
}
.social-icons.light li a:hover {
  color: #FFD15C;
}

/* === Go to Top === */
#return-to-top {
  position: fixed;
  bottom: 25px;
  right: 25px;
  background: rgba(0, 0, 0, 0.2);
  width: 40px;
  height: 40px;
  display: block;
  text-decoration: none;
  border-radius: 100%;
  display: none;
  z-index: 4;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#return-to-top i {
  color: #fff;
  margin: 0;
  position: relative;
  left: 13px;
  top: 8px;
  font-size: 16px;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

#return-to-top:hover {
  background: #FF4C60;
}

/* === Helper === */
.rounded {
  border-radius: 20px !important;
}

.bg-white {
  background: #FFF;
}

.shadow-dark, .desktop-header-3 .dropdown-menu, .form-control, .form-control:focus {
  -webkit-box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.1);
  box-shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.1);
}

.shadow-light {
  -webkit-box-shadow: 0px 5px 20px 0px rgba(255, 255, 255, 0.01);
  box-shadow: 0px 5px 20px 0px rgba(255, 255, 255, 0.01);
}

.shadow-blue {
  -webkit-box-shadow: 0px 5px 20px 0px rgba(108, 108, 229, 0.5);
  box-shadow: 0px 5px 20px 0px rgba(108, 108, 229, 0.5);
}

.shadow-pink {
  -webkit-box-shadow: 0px 5px 20px 0px rgba(249, 123, 139, 0.5);
  box-shadow: 0px 5px 20px 0px rgba(249, 123, 139, 0.5);
}

.shadow-yellow {
  -webkit-box-shadow: 0px 5px 20px 0px rgba(249, 215, 76, 0.5);
  box-shadow: 0px 5px 20px 0px rgba(249, 215, 76, 0.5);
}

.padding-30 {
  padding: 30px;
}

.triangle-left:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 15px solid #FFF;
  position: absolute;
  left: -15px;
  top: 20%;
}

.triangle-top:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #FFF;
  position: absolute;
  left: 50%;
  top: -10px;
  -webkit-transform: translateX(-7.5px);
      -ms-transform: translateX(-7.5px);
          transform: translateX(-7.5px);
}

.morphext > .animated {
  display: inline-block;
}

.text-light h1, .text-light h2, .text-light h3, .text-light h4, .text-light h5, .text-light h6 {
  color: #FFF;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 0;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mfp-zoom-in {
  /* start state */
  /* animate in */
  /* animate out */
}
.mfp-zoom-in .mfp-with-anim {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0.8);
      -ms-transform: scale(0.8);
          transform: scale(0.8);
}
.mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.mfp-zoom-in.mfp-ready .mfp-with-anim {
  opacity: 1;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}
.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}
.mfp-zoom-in.mfp-removing .mfp-with-anim {
  -webkit-transform: scale(0.8);
      -ms-transform: scale(0.8);
          transform: scale(0.8);
  opacity: 0;
}
.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

/* Styles for dialog window */
.white-popup {
  background: white;
  border-radius: 25px;
  padding: 30px;
  text-align: left;
  max-width: 650px;
  margin: 40px auto;
  position: relative;
}

/**
 * Fade-zoom animation for first dialog
 */
/* start state */
.my-mfp-zoom-in .zoom-anim-dialog {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
}

/* animate in */
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

/* animate out */
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
}

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

.mfp-close {
  font-size: 40px;
  opacity: 1;
}
.mfp-close:hover {
  opacity: 0.75;
}

/*=================================================================*/
/*                      SLICK SETTINGS
/*=================================================================*/
.slick-slide {
  height: auto;
  outline: none;
}

.slick-next, .slick-prev {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #454360;
  border-radius: 0;
  top: calc(50% - 40px);
  z-index: 1;
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}

.slick-next:hover, .slick-next:focus, .slick-prev:hover, .slick-prev:focus {
  background: #454360;
}

.slick-prev {
  left: 0;
}
.slick-prev:hover {
  background: #454360;
}

.slick-next {
  right: 0;
}
.slick-next:hover {
  background: #454360;
}

.slick-next:before, .slick-next:after {
  left: 20px;
}

.slick-prev:before, .slick-prev:after {
  left: 17px;
}

.slick-prev:before {
  content: "";
  background: #FFF;
  display: block;
  width: 1px;
  height: 14px;
  top: 8px;
  position: absolute;
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}

.slick-prev:after {
  content: "";
  background: #FFF;
  display: block;
  width: 1px;
  height: 14px;
  position: absolute;
  top: 17px;
  -ms-transform: rotate(135deg);
  /* IE 9 */
  -webkit-transform: rotate(135deg);
  /* Chrome, Safari, Opera */
  transform: rotate(135deg);
}

.slick-next:before {
  content: "";
  background: #FFF;
  display: block;
  width: 1px;
  height: 14px;
  top: 8px;
  position: absolute;
  -ms-transform: rotate(-45deg);
  /* IE 9 */
  -webkit-transform: rotate(-45deg);
  /* Chrome, Safari, Opera */
  transform: rotate(-45deg);
}

.slick-next:after {
  content: "";
  background: #FFF;
  display: block;
  width: 1px;
  height: 14px;
  position: absolute;
  top: 17px;
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}

.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.slick-dots {
  position: relative;
  bottom: 0;
  padding: 0;
  margin: 0;
}

.slick-dots li {
  width: 10px;
  height: 6px;
  margin: 0 5px;
}

.slick-dots li.slick-active {
  width: 20px;
  height: 6px;
}

.slick-dots li button {
  width: 10px;
  height: 6px;
  padding: 0;
}

.slick-dots li.slick-active button:before {
  background: #FF4C60;
  border-radius: 3px;
  opacity: 1;
  height: 6px;
  width: 20px;
}

.slick-dots li button:before {
  content: "";
  background: #D4D4FF;
  border-radius: 3px;
  opacity: 1;
  height: 6px;
  width: 10px;
}

/*=================================================================*/
/*                      RESPONSIVE SETTINGS
/*=================================================================*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  html,
body {
    width: 100%;
    overflow-x: hidden;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .price-item {
    padding: 40px 30px;
  }
}
@media only screen and (max-width: 991px) {
  header.desktop-header-1 {
    -webkit-transform: translateX(-290px);
    -ms-transform: translateX(-290px);
    transform: translateX(-290px);
  }

  header.mobile-header-1 {
    display: block;
  }

  main.content {
    margin-left: 0;
  }

  header.desktop-header-2 {
    -webkit-transform: translateX(-110px);
    -ms-transform: translateX(-110px);
    transform: translateX(-110px);
  }

  header.mobile-header-2 {
    display: block;
  }

  main.content-2 {
    margin-left: 0;
  }

  .desktop-header-3 .nav-link {
    padding: 7px 0;
  }
}
@media only screen and (max-width: 768px) {
  section.home {
    padding: 300px 0;
  }

  .portfolio-filter {
    display: none;
  }

  .portfolio-item, .blog-item {
    max-width: 360px;
    margin: auto;
  }

  .blog-wrapper {
    margin: -20px 0;
  }
  .blog-wrapper .blog-item {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  section.home .cta h1 {
    font-size: 52px;
  }

  section.home .cta p {
    font-size: 18px;
  }

  .pf-filter-wrapper {
    display: block;
  }

  .triangle-top-sm:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #FFF;
    position: absolute;
    left: 50%;
    top: -10px;
    -webkit-transform: translateX(-7.5px);
        -ms-transform: translateX(-7.5px);
            transform: translateX(-7.5px);
  }

  body.dark .triangle-top-sm:before {
    border-bottom-color: #302f4e;
  }
}
@media only screen and (min-width: 768px) {
  .container {
    max-width: 920px;
  }

  .triangle-left-md:before {
    content: "";
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 15px solid #FFF;
    position: absolute;
    left: 0;
    top: 20%;
  }

  body.dark .triangle-left-md:before {
    border-right-color: #302f4e;
  }
}
@media only screen and (max-width: 576px) {
  .portfolio-info li {
    display: block;
    padding: 5px 0;
  }

  .parallax .p1 {
    left: 10%;
    top: 10%;
  }
  .parallax .p2 {
    left: 15%;
    top: 30%;
  }
  .parallax .p3 {
    left: 10%;
    bottom: 30%;
  }
  .parallax .p4 {
    left: 10%;
    bottom: 10%;
  }
  .parallax .p5 {
    left: 45%;
    top: 3%;
  }
  .parallax .p6 {
    left: 40%;
    bottom: 10%;
  }
  .parallax .p7 {
    top: 20%;
    right: 30%;
  }
  .parallax .p8 {
    right: 30%;
    bottom: 20%;
  }
  .parallax .p9 {
    right: 10%;
    top: 5%;
  }
  .parallax .p10 {
    top: 45%;
    right: 10%;
  }
  .parallax .p11 {
    bottom: 10%;
    right: 10%;
  }
}
@media only screen and (max-width: 380px) {
  .blog-page-section .blog-pagination ul li {
    height: 40px;
    width: 40px;
    line-height: 40px;
    margin: 0 5px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 920px;
  }
}
@media (min-width: 1366px) {
  .container {
    max-width: 1080px;
  }
}
@media only screen and (max-height: 500px) {
  .scroll-down {
    display: none;
  }
}
@media only screen and (max-height: 667px) {
  header.desktop-header-2 .footer {
    margin-top: 100px;
  }
}